.auditProduct {
  &.title {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }
  p {
    margin: 0;
  }
  p:last-child {
    font-size: 11px;
    font-weight: 600;
    opacity: 0.75;
  }
}