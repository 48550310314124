.optionContainer {
  .optionLabelVN, .optionLabelEN {
    display: block;
  }
  .optionLabelVN {
    font-size: 14px;
  }
  .optionLabelEN {
    font-size: 11px;
    font-weight: 600;
  }
}