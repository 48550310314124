.formula-description {
  .formula, .formula-output {
    margin-top: 10px;
    margin-bottom: 4px;
    margin-left: 10px;

    > span {
      font-weight: 600;
    }
  }

  .formula-list-keyword-title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .formula-keyword {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;
    & + .formula-keyword {
      margin-top: 4px;
    }

    span:first-child {
      font-weight: 500;
    }
  }

  .formula-example {
    margin-top: 15px;
    border-top: 1px dashed #ced4da;
    padding-top: 15px;

    .formula-example-title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 3px;
      margin-top: 0;
    }

    .formula-input-title {
      margin-top: 0;
      margin-bottom: 4px;
      font-weight: 500;
      margin-left: 10px;
    }

    .formula-input {
      margin-left: 20px;

      & + .formula-input {
        margin-top: 5px;
      }

      span {
        font-weight: 500;
      }
    }
  }
}
