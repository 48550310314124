.productWrapper {
  border-top: dashed 1px #b2b2b2;
  margin-top: 10px;
  padding-top: 10px;

  .groupBtnAction{
    button + button {
      margin-left: 5px;
    }
  }

  .moreInfo {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    &.active {
      overflow: visible;
    }

    .row5 {
      margin-right: -2px;
      margin-left: -2px;

      .col5 {
        padding-right: 2px;
        padding-left: 2px;
      }
    }
  }
}

.textAlignRight {
  text-align: right;
}

.transparent {
  color: transparent;
}