.buttons {
  text-align: right;
  button + button {
    margin-left: 5px;
  }
}

.messgaeError {
  p {
    margin: 0;
  }
}